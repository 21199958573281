import { ColDef } from "ag-grid-community";
import { ButtonTypes } from "../../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { AudienceField, Enums, Translator, UiSizes } from "../../../../../../enums";
import { AudiencesApi } from "../../../../../../services/api/v2/audiences/Audiences.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { UiActionRenderers } from "../../../../../uiAction/IUiAction";
import { AudienceColDefFieldNamesEnum, CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { UiActionColumnBuilder } from "../../columns/commonColumns/UiActionColumn/UiActionColumn_builder";
import {} from "../ImpactGroupsGrid/modals/noteCountModal/ImpactGroupNotesSidebar";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { SHOW_ADD_PEOPLE_MODAL } from "./AudiencesGrid_modals";
import { zeroToTenTextMatcher } from "../../../../filters/TextMatcher";
import { CircleStatus, CircleStatusColours } from "../../../../../../components/ui/CircleStatus";
import { AUDIENCE_ACTIONS_SIDEBAR_MODAL_CONFIG } from "./modals/AudienceActions/AudienceActionsModal_config";
import { AUDIENCE_IMPACTS_SIDEBAR_MODAL_CONFIG } from "./modals/AudienceImpacts/AudienceImpactsModal_config";
import _ from "lodash";
import { DynamicColumnBuilder } from "../../columns/commonColumns/DynamicColumn/DynamicColumn_builder";
import { DYNAMIC_COLUMN_CONFIG } from "../../columns/commonColumns/DynamicColumn/DynamicColumn_config";
import CustomFieldsApi from "../../../../../../services/api/v2/customFields/CustomFields.api";

export interface AudiencesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewAudiences: boolean;
  columns: FP.Entities.IColumnDef[];
  audiencesProvider: AudiencesApi;
}

export class AudiencesGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: AudiencesGridColumnBuilderProps;
  columnDefs: Dictionary<(header?: string) => ColDef>;
  organisationId: number;
  projectId: number;
  levelClassRules: any;

  constructor(gridProps: AudiencesGridColumnBuilderProps) {
    super(gridProps.audiencesProvider.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.levelClassRules = {
      "stakeholder-grid__cell--unknown": params =>
        params.value <= 0 || typeof params.value === "undefined" || params.value === null,
      "stakeholder-grid__cell--low": params => params.value >= 1 && params.value <= 4,
      "stakeholder-grid__cell--medium": params => params.value >= 5 && params.value <= 7,
      "stakeholder-grid__cell--high": params => params.value > 7
    };

    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: (header?: string) => this.buildNameColumn(header),
      [AudienceColDefFieldNamesEnum.Impact]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Impact,
          AudienceField.impact,
          `${header || I18n.t("grids.impact")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.ImpactLevelMapped(),
          zeroToTenTextMatcher
        )
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            getQuickFilterText: params => {
              if (!params.data.influence) return "Unknown";

              return Enums.Translator.ImpactLevel(params.value);
            },
            valueFormatter: params => {
              const impact = Translator.ImpactLevelMapped().find(e => e.key === params.value + "");
              return impact ? impact.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Size]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Size,
          AudienceField.size,
          `${header || I18n.t("grids.size")}`,
          this.gridProps.canEdit,
          Translator.SizeMapped(),
          zeroToTenTextMatcher
        )
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            getQuickFilterText: params => {
              if (!params.data.influence) return "Unknown";

              return Enums.Translator.Size(params.value);
            },
            valueFormatter: params => {
              const impact = Translator.SizeMapped().find(e => e.key === params.value + "");
              return impact ? impact.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Influence]: (header?: string) =>
        this.buildProfilingColumn(
          AudienceColDefFieldNamesEnum.Influence,
          AudienceField.influence,
          `${header || I18n.t("grids.influence")} ${String.fromCodePoint(0x00002139)}`,
          this.gridProps.canEdit,
          Translator.InfluenceLevelMapped(),
          zeroToTenTextMatcher
        )
          .setColumnOptions({
            cellClassRules: this.levelClassRules,
            valueFormatter: params => {
              const influence = Translator.InfluenceLevelMapped().find(e => e.key === params.value + "");
              return influence ? influence.label : "Unknown";
            }
          })
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Count]: (header?: string) =>
        new NameColumnBuilder({
          field: AudienceColDefFieldNamesEnum.Count,
          headerName: header || I18n.t("grids.count"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.Awareness]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Awareness,
          header || AudienceColDefFieldNamesEnum.Awareness
        ),
      [AudienceColDefFieldNamesEnum.Understanding]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Understanding,
          header || AudienceColDefFieldNamesEnum.Understanding
        ),
      [AudienceColDefFieldNamesEnum.Commitment]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Commitment,
          header || AudienceColDefFieldNamesEnum.Commitment
        ),
      [AudienceColDefFieldNamesEnum.Capability]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Capability,
          header || AudienceColDefFieldNamesEnum.Capability
        ),
      [AudienceColDefFieldNamesEnum.Adoption]: (header?: string) =>
        this.buildAudienceProfilingColumn(
          AudienceColDefFieldNamesEnum.Adoption,
          header || AudienceColDefFieldNamesEnum.Adoption
        ),
      [CommonColDefFieldNamesEnum.Impacts]: (header?: string) =>
        this.buildJSONPillsColumn(
          CommonColDefFieldNamesEnum.Impacts,
          header || I18n.t("grids.impacts"),
          AUDIENCE_IMPACTS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.ImpactActions]: (header?: string) =>
        this.buildJSONPillsColumn(
          AudienceColDefFieldNamesEnum.ImpactActions,
          header || I18n.t("grids.actionsFromImpacts")
        )
          .makeEditable(false)
          .makeSelectable(false)
          .makeReadOnly(true)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedBy]: (header?: string) =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: header || I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: (header?: string) =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedAt,
          headerName: header || I18n.t("grids.createdOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: (header?: string) =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: header || I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [AudienceColDefFieldNamesEnum.UiActionCol]: (header?: string) =>
        new UiActionColumnBuilder({
          field: AudienceColDefFieldNamesEnum.UiActionCol,
          headerName: "",
          pinned: "right",
          width: 64
        })
          .makeEditable(this.canEdit)
          .makeReadOnly(!this.canEdit)
          .makeSelectable(this.canEdit)
          .setActions([
            {
              id: "add",
              label: I18n.t("phrases.addPeople"),
              onAction: this.showLinkStakeholderModal,
              componentProps: {
                className: "mt-2",
                type: ButtonTypes.PRIMARY,
                symbol: IconSymbols.Plus,
                size: UiSizes.XXS,
                isDisabled: !this.canEdit
              },
              rendersIn: UiActionRenderers.BUTTON_ICON
            }
          ])
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: (header?: string) =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: header || I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .generateColumnOptions()
    };
  };

  showLinkStakeholderModal = (k, i) => {
    this.gridModalBuilder.constructPopupModal().setModalOptions(SHOW_ADD_PEOPLE_MODAL(k, i)).generateModal();
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    if (this.gridProps.columns.length === 0) {
      return _.map(this.columnDefs, e => {
        return e();
      });
    }

    res.push(this.columnDefs[CommonColDefFieldNamesEnum.Selected]());

    this.gridProps.columns.forEach(e => {
      if (e.isCore) {
        let col = this.columnDefs[e.colName];
        if (typeof col === "function") {
          res.push(col(e.header));
        } else {
          throw new Error(`Typeof columnDef with name "${e.colName}" is not a function`);
        }
      } else {
        let col = new DynamicColumnBuilder(
          DYNAMIC_COLUMN_CONFIG({
            field: e.colName,
            headerName: e.header
          })
        )
          .makeSelectable(this.gridProps.canEdit)
          .makeEditable(this.gridProps.canEdit)
          .makeReadOnly(!this.gridProps.canEdit);

        if (this.gridProps.canEdit) {
          col.setEditableOnDoubleClick(this.updateDynamicFieldValue(e.customFieldId), e.colName, e.header);
        }
        res.push(col.generateColumnOptions());
      }
    });

    res.push(this.columnDefs[AudienceColDefFieldNamesEnum.UiActionCol]());

    return res;
  };

  buildAudienceProfilingColumn = (field: string, headerName: string) => {
    let model = new SimpleTextColumnBuilder({
      field: field,
      headerName: headerName,
      valueGetter: params => {
        const val = params.data[field];
        if (!val) return "";
        return `${val.toFixed(2)} - ${GetLabelByValue(val)}`;
      },
      cellRenderer: e => {
        let value = e.data[field];
        if (!value) value = 0;
        return (
          <>
            <CircleStatus colour={GetColour(value)} className="mr-2" /> {value.toFixed(2)} - {GetLabelByValue(value)}
          </>
        );
      }
    })
      .makeEditable(false)
      .makeReadOnly(true)
      .setFilterOptions(BASE_FILTER_CONFIG);
    return model.generateColumnOptions();
  };

  buildNameColumn = (header?: string) => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: header || "Name", cellRenderer: "agGroupCellRenderer" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  //#region Update Field Methods
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(AudienceField.name, entityId, text);
  };

  updateDynamicFieldValue = customFieldId => async (entityId: number, text: string) => {
    const customFieldProvider = CustomFieldsApi;
    await customFieldProvider.updateImpactCustomFieldValue(
      this.organisationId,
      this.projectId,
      customFieldId,
      entityId,
      text
    );
  };

  //#endregion
}

const GetLabelByValue = value => {
  if (value === 0) {
    return "Unknown";
  } else if (value < 2.5) {
    return "Cautious";
  } else if (value >= 2.5 && value <= 3.5) {
    return "Neutral";
  } else {
    return "Advocate";
  }
};

const GetColour = value => {
  if (value <= 0) {
    return CircleStatusColours.GREY;
  } else if (value > 0 && value < 2.5) {
    return CircleStatusColours.RED;
  } else if (value >= 2.5 && value <= 3.5) {
    return CircleStatusColours.BLUE;
  } else {
    return CircleStatusColours.GREEN;
  }
};
