import ModalContext from "../../../../../modalZ/context/ModalContext";
import {
  GridSideModalAnimationOpts,
  GridSideModalComponentProps,
  GridSideModalTitle
} from "../../utils/GridModalUtils";
import { EditRichTextSidebar } from "./EditRichTextSidebar_view";

export const showEditRichTextModal = async (
  item: any,
  saveFn: (entityId: number, text: string) => Promise<any>,
  field: string,
  header: string
) => {
  ModalContext.show({
    showClose: true,
    title: <GridSideModalTitle name={"Impact"} field={item.name} />,
    content: (
      <EditRichTextSidebar
        field={field}
        label={header}
        placeholder="Add your new text here"
        hideModal={() => {
          ModalContext.hide();
        }}
        saveFn={async (text: string) => {
          await saveFn(item.id, text);
        }}
        item={item}
      />
    ),
    componentProps: GridSideModalComponentProps,
    animationOptions: GridSideModalAnimationOpts
  });
};
